
.Footer,.copyright
{
    padding: 10vh 5vw;
    width: 100vw;
    background: #111;
    /* position: fixed; */
    bottom: 0;
    left: 0;
    
}
.footerLogo
{
    width: 100px;
    height: auto;
}
.footerSocials
{
    display: flex;
    width: fit-content;
 
    padding: 12px 20px;
}
.FooterTopBar
{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
} 
@media screen and (max-width:480px)
{
    .FooterTopBar
    {   flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .footerSocials
    {
        margin-top: 20px;
        padding: 0;
    }
    .FooterContent
    {
        display: block;
    }
  

}
.FooterTopBar::after
{
    content: '';
    position: relative;
    width: 90%;
    margin: 30px auto;
    height: 1px;
    background: rgba(238, 238, 238, 0.089);
}
.footerSocials
{
    display: flex;
    align-items: center;
    
}
.footerSocials>span
{
    margin-right: 10px;color: #fff;
}
.footerSocials>a
{
    border: 1px solid #eee;
    padding: 3px 8px;
    font-size: 17px;
    color: var(--secondary-color);
   transition: 0.4s ease;
}
.footerSocials>a:nth-of-type(2)
{
    border-left: transparent;
    /* border-right: transparent; */
}
.footerSocials>a:nth-of-type(3)
{
    border-right: transparent;
}
.footerSocials>a:hover
{
    background: #eee;
}
.FooterContent
{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
}
.FooterContent>div

{
    margin-top: 40px;
}
.FooterConentTitle
{
    color: var(--secondary-color);
    margin-bottom: 20px;
}

.FooterContentPara
{
    color: rgb(218, 214, 214);
    font-size: 14px;
    
}
.FooterContact
{
    color: rgb(218, 214, 214);
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    
}
.FooterContact>span
{
text-align: left;
/* margin-right: 10px; */

}
.key
{
    width: 100px;
}
.FooterContact a
{
    text-decoration: none;
    text-align: left;
    color: rgb(218, 214, 214) ;
    transition: 0.3s ease;
}
.FooterContact a:hover
{
    color: var(--secondary-color);
}
#newsletterForm
{
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    margin-top: 12px;
}

#newsletterForm>input
{
background-color: #292929;
border: 1px solid rgba(238, 238, 238, 0.192);
width: 60%;
padding: 20px;
color: var(--white-text);
transition: 0.5s ease;
}
#newsletterForm>input:focus
{
    outline: 0;
    background: rgba(238, 238, 238, 0.233);
}
#newsletterForm>input,#newsletterForm>button
{
    height: 50px;
  
}
#newsletterForm>button
{
    background: var(--secondary-color);
    color: var(--white-text);
    width: 30%;
    margin-left: 12px;
    transition: 0.4s ease;
    outline: 0;
    border: 1px solid var(--secondary-color);
    font-weight: 500;
    font-size: 18;
    letter-spacing: 1px;
}

#newsletterForm>button:hover
{
    background: #fff;
    color: var(--primary-color);
}
.copyright
{
    
    padding: 2.5vh 0 ;
    color: var(--white-text);
    background: var(--primary-color);
}