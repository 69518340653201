
.aboutBrush,#contactBrush
{
    width: 100vw;
    height: 150px;

    background: url('../../images/blackBrush.png');
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden !important;
    margin-top: -30px;
    /* transform: rotate(180deg); */
    filter: var(--brush-filter);
    background-position: top;
}

@media screen  and (max-width:1100px){

   .aboutBrush
   {
       /* background-size:cover ; */
       margin-top: -20px;
   } 
}
@media screen  and (max-width:600px){

    .aboutBrush
    {
        /* background-size:cover ; */
        margin-top: -10px;
    } 
 }
.about
{   position: relative;
    padding: 10vh 10vw;
    overflow: hidden !important;
    background: var(--primary-color);
}
.topBorder
{
    width: 100px;
    height: 100px;
    background: transparent;
    position: absolute;
    top: 5%;
    left: 5%;
    border-top: 1px dotted var(--secondary-color);
    border-left: 1px dotted var(--secondary-color);
}
.about>.row
{
    justify-content: space-between;
}
.about>.row>div

{
    margin-top: 40px;
}
.aboutImg
{
    width: 100%;
    height: auto;
}
.aboutDetail
{
    color: var(--white-text);
    position: relative;
}
.aboutDetail>div>span
{
    color: var(--secondary-color);
    font-style: italic;
    font-size: 18px;
}
.aboutDetail span,.aboutDetail h3
{
    
    font-family:var(--serif-font);
}
.aboutDetail h3
{
    color: var(--white-text);
    font-size: 28px;
    font-weight: 600;
}
.aboutDetail p
{
    color: var(--white-text);
    font-size: 16px;
}
.aboutImg:nth-of-type(1)
{
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.548) 0px 3px 8px;
    border: 1px dotted var(--secondary-color);
    /* background: var(--primary-color); */
    padding: 8px;
    
}

.aboutImg:nth-of-type(2)
{
    position: absolute;
    left: 95%;
    z-index: 0;
    transform: scale(1.2);box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.borderBox
{   
    height: 100%;
    width: 100%;
    position: absolute;
    top: 20%;
    right: 0;
    z-index: -1;
    background: transparent;
    border: 1px dotted var(--secondary-color);
}
.aboutBtn
{
    color: var(--white-text);
}
.aboutBtn:hover
{
    background: var(--secondary-color);
    color: var(--dark-text);
}
.spiceRow
{
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    padding: 5vh 0;
}
#spiceImg
{
    position: absolute;
    left: -95% !important;
    top: 5%;
}
@media screen and (max-width:800px) {

    .aboutImg:nth-of-type(2),#spiceImg
{
    display: none;
}
}


.AboutWrapper
{
    background: url('../../images/DSC_9552.jpg') rgba(17, 17, 17, 0.438);
    background-size: cover;
    background-attachment: fixed;
    background-blend-mode: darken;
  
}
.aboutParaWrapper
{
    margin: 30px 0;
}
.aboutTeam
{
    background: rgb(0, 0, 0) !important;
}
.aboutTeam .bigText
{
    color: #fff !important;
}