:root
{
  --primary-color: #292929;
  /* --primary-color: #fff; */
  --secondary-color: #C19D60;
  /* --secondary-color: #d64e4e; */
  --white-text:#fff;
  /* --white-text:#111; */
  /* --dark-text:#fff; */
  --dark-text:#111;
  
  --brush-filter:brightness(124%);
  --serif-font:'Playfair Display', serif;
  --sans-serif-font:'Playfair Display', serif;
}

*
{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
 
}
.row
{
  margin: 0 !important;
  padding: 0 !important;
}
html
{
  
  scroll-behavior: smooth;
  
}
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--primary-color);
  max-width: 100vw !important;
  overflow-x:hidden !important;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a,a:active,a:hover,a:focus
{
  text-decoration: none;
  
  
}
a:focus
{
  color: var(--secondary-color);
}
input:focus{
  outline: 0 !important;
  
  box-shadow: none;
}
.dots
{
  color: var(--secondary-color) !important;
}
.none
{
  display: none;
}