  .signatureDishes
  {

        padding-bottom: 5vh ;
        position: relative;
        background: url('../../images/floarl2.png') var(--primary-color);
        background-blend-mode: normal;
      
        background-size: cover;
        background-repeat: no-repeat;
  }

  .box-item {
    position: relative;
    -webkit-backface-visibility: hidden;
    width: 415px;
    margin-bottom: 35px;
    max-width: 100%;
    
  }
  
  .flip-box {
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    perspective: 1000px;
    -webkit-perspective: 1000px;
    
  }
  
  .flip-box-front,
  .flip-box-back {
    background-size: cover !important;
    background-position: center !important;
    background-blend-mode: darken !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 8px;
    min-height: 475px;
    -ms-transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
    transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
    -webkit-transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-box-front {
    -ms-transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  
  .flip-box:hover .flip-box-front {
    -ms-transform: rotateY(-180deg);
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  
  .flip-box-back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    
    -ms-transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  
  .flip-box:hover .flip-box-back {
    -ms-transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  
  .flip-box .inner {
    position: absolute;
    left: 0;
    width: 100%;
    padding: 60px;
    outline: 1px solid transparent;
    -webkit-perspective: inherit;
    perspective: inherit;
    z-index: 2;
    color: #fff;
    transform: translateY(-50%) translateZ(60px) scale(.94);
    -webkit-transform: translateY(-50%) translateZ(60px) scale(.94);
    -ms-transform: translateY(-50%) translateZ(60px) scale(.94);
    top: 50%;
  }
  
  .flip-box-header {
    font-size: 34px;
    color: #fff;
  }
  
  .flip-box p {
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 1.5em;
    text-transform: uppercase;
    font-family: Poppins,sans-serif;
    color: #fff;
  }
  
  .flip-box-img {
    margin-top: 25px;
  }
  
  .flip-box-button {
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    margin-top: 25px;
    padding: 15px 20px;
    text-transform: uppercase;
    
  }

  .signatureCardWrapper
  {
   display: flex;
   flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .signatureCardWrapper .Box
  {
      width: 70px;
      height: 70px;
      position: absolute;
      
      
  }
  .borderBoxTop
  {
    top: 4%;
    left: 4%;
    border-top: 1px dotted #eee;
    border-left: 1px dotted #eee;
  }
  .borderBoxBottom
  {
      bottom: 4%;
      right: 4%;
      border-bottom: 1px dotted #eee;
      border-right: 1px dotted #eee;
  }
  .CardBottom
  {
      position: absolute;
      bottom: 3%;
      left: 3%;
      font-family: var(--serif-font);
      font-size: 21px;
      color: #fff;
  }
  .signatureBtn
  {
    color: var(--white-text);
  }
  .signatureBtn:hover
  {
    background: var(--secondary-color);
  }
  @media screen and (max-width:480px) {
      .box-item
      {
          max-width: 100vw;
      }
     .hourDiv
     {
         background-size: contain;
         background-repeat: no-repeat;
         padding: 10vh 0;
     }
      
  }