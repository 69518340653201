.menuBrush
{
    width: 100vw;
    height: 200px;
    position: absolute;
    background: url('../../images/blackBrush.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    overflow: hidden !important;
    pointer-events: none;
    filter: brightness(124%);
    transform: rotate(180deg);
    margin-top: -5px;

}
.menuBrushBottom
{
    width: 100vw;
    height: 200px;
    position: absolute;
    background: url('../../images/blackBrush.png');
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden !important;
    pointer-events: none;
    filter: brightness(124%);
    transform: rotate(180deg);
    background-position:bottom;
    margin-top: -3%;
}
@media screen and (max-width:1100px) {
    .menuBrush
    {
        margin-top: 0;
    }
}

.menuSmall
{
    background: url('../../images/background2.jpg') rgba(0, 0, 0, 0.822);
   
    background-size: cover;
    background-blend-mode: darken !important;
    background-position: center;
    background-attachment: fixed;
    padding: 5vh 0;
    color: rgba(255, 255, 255, 0.205);
}


.menuCardWrapper
{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    max-width: 90vw;
    margin: 0 auto;
}

.menuCard
{
    margin: 20px ;
 
    padding: 0 !important;
    position: relative;
    overflow: hidden;
    transition: 0.5s ease !important;
    border-bottom: 1px solid var(--white-text);
    box-shadow: rgba(0, 0, 0, 0.589) 0px 3px 8px;
}
.menuCardDetails
{
    position: absolute;
    background: var(--white-text);
    bottom: 0;    
    width: 100%;
    padding: 12px 20px;
    border: 1px solid var(--white-text);
    border-bottom: transparent;    
    transform: translateY(60%);
    transition: 0.5s ease;
    min-height: 200px;
    
}

.menuImage
{
    width: 100% !important;
    /* height: 400px; */
    overflow: hidden;
}

.menuImage>img
{
    width: 100%;
    height: auto;
    object-position: center;
    transition: 0.5s ease;
}
.menuCard:hover .menuImage>img
{
    transform: translateY(-20%) scale(1.05);
}
.menuCardTop
{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
}
.menuCardTop>div
{
    padding: 0 !important;
}
.menuCardTop>col-xs-4
{
   text-align: right;
}
.recipeName,.recipeType
{
    color: var(--primary-color);
}
.recipeName
{
    font-size: 110%;
    font-weight: 600;
    
}
.recipeType
{
    margin-top: 6px;
}
.veg
{
    color: green;
}
.nonveg
{
    color: red;
}
.recipePrice
{
    color: var(--secondary-color);
    font-size: 21px;
    font-weight: 600;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: center;
}
.recipePrice>img
{
    width: 20px;
    height: 20px;
    margin-right: 3px;
}
.menuCardDesc
{
    margin-top: 20px;
    color: var(--primary-color);
    opacity: 0.7;
/*     
    height: 0;
    overflow: hidden; */
}
.menuCard:hover .menuCardDetails
{   
    
    transform: translateY(0);

}


.MenuWrapper
{
    background: url('../../images/menu/1.jpg') rgba(0, 0, 0, 0.555);
    background-size: cover;
    background-blend-mode: darken !important;
    background-position: 8%;
    background-attachment: fixed;
}
@media screen and (max-width:1000px) {
    .menuCardDetails
    {
        position: relative;
        transform: translateY(0);
        border: transparent;
    }
    .menuCard
    {
        border: 1px solid var(--white-text);
    }
    
}
@media screen and (max-width:800px) {
    .menuCard:hover .menuImage>img
{
    transform: translateY(0) scale(1.05);
}
}
.specialMenu
{
    border: 1px solid #fff;
}
.specialMenu .menuCardDetails
{
    background: #fff ;
    border-color: transparent;
    /* border-top: 1px solid #fff; */

}
.specialMenu .recipeName,.specialMenu .menuCardDesc
{
    color: #292929 ;
}