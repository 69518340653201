.button {
    display: inline-block;
    position: relative;
  }
 
  .button.is-play {
    background-color: rgba(255, 255, 255, 0.719);
    border-radius: 50%;
    width: 80px;
    height: 80px;
  }
  .button:hover>.button.is-play
  {
      background: #fff !important;
      transition: 0.5s ease;
  }
  .button.is-play .button-outer-circle {
    background: rgb(255, 255, 255);
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
  }
  .button.is-play .button-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    transform: translate(-50%, -50%);
  }
  .button.is-play .button-icon .triangle {
    -webkit-animation: fadeIn 7s ease;
            animation: fadeIn 7s ease;
  }
  .button.is-play .button-icon .path {
    stroke-dasharray: 90;
    stroke-dashoffset: 0;
    -webkit-animation: triangleStroke 3s;
            animation: triangleStroke 3s;
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease;
  }
  .has-scale-animation {
    -webkit-animation: smallScale 3s infinite;
            animation: smallScale 3s infinite;
  }
  .has-delay-short {
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
  }
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-webkit-keyframes triangleStroke {
    from {
      stroke-dashoffset: 90;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
  @keyframes triangleStroke {
    from {
      stroke-dashoffset: 90;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
  @-webkit-keyframes smallScale {
    from {
      transform: scale(1);
      opacity: 1;
    }
    to {
      transform: scale(1.5);
      opacity: 0;
    }
  }
  @keyframes smallScale {
    from {
      transform: scale(1);
      opacity: 1;
    }
    to {
      transform: scale(1.5);
      opacity: 0;
    }
  }
  