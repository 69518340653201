.form-row
{
    margin: auto !important;
    padding:0;
}
.reservationModal
{
    width: 100vw;
    height: 100vh;
    background: rgba(17, 17, 17, 0.932);
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: scroll;
    display: flex;
    align-items: center;
    justify-content: center;
    /* backdrop-filter: blur(14px); */
    transform: scale(0);
    opacity: 0;
    pointer-events: none;
    transition: 0.4s ease;
    

}

.openModal
{
    transform: scale(1);
    opacity: 1;
    pointer-events: all;
    transition: 0.4s ease;
}
.closeBtn
{   position: absolute;
    top: 12%;
    right: 12%;
    font-size: 30px;
    border: 1px solid var(--secondary-color);
    padding: 6px 20px;
    color: #fff;
    transition: 0.4s ease;
    background: #292929;
}
.closeBtn:hover
{
    background: var(--secondary-color);
    border: 1px solid #fff;
    
}
.reservationFormWrapper
{
    width: 60vw;
    height: 70vh;
    border: 1px solid #fff;
    background: #57575779;
}
#reservationForm
{
    width: 90%;
    height: 90%;    
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    margin: 0 auto;
    overflow-y: scroll;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 10vh;
    background: url('../../images/floarl2.png');
    background-size: cover;
    
}
#reservationForm::-webkit-scrollbar
{
    display: none;
}
@media screen and (max-width:480px) {

    .reservationFormWrapper
   {
       width: 90vw;
       height: 90vh;
   } 
   .closeBtn
   {
       top: 2%;
       right: 2%;
   }
}
::placeholder
{
    color: #fff !important;
}
#reservationForm>.form-row
{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  
}
.reservationInput
{
    background: #292929;
    height: 50px;
    padding: 12px;
    margin: 10px 0;
    width: 100%;
    color: #fff;
    outline: none;
    border: 1px solid #eee;
    transition: 0.4s ease;
    
}
.reservationInput:focus
{
    outline: none;
    border-color: var(--secondary-color);
    
}

.modalBtn
{
    margin-top: 15px !important;
    width: 100%;
    font-size: 22px;
    color: #fff !important;
}
.modalBtn:hover
{
    color: var(--secondary-color) !important;
}
.event
{
    color: #fff;
    text-align: center;
}
.eventBtn
{   padding:12px 20px ;
    border: 1px solid var(--secondary-color);
    background:#292929;
    color: #fff;
    transition: 0.5s ease;
    font-size: 18px;
    margin-top: 50px;
}
.eventBtn:hover
{
    background: #fff;
    color: #292929;
}

.storeDiv
{
    background: url('../../images/DSC_0107.jpg') no-repeat rgba(17, 17, 17, 0.637);
    background-blend-mode: darken;
    background-position: center;
    /* background-attachment: fixed; */
    background-size: cover;
}
.storeWrapper>div>h3
{
    color: var(--secondary-color);
    font-size: 5vh;
}
.storeWrapper>div>span
{
    color: #fff;
    letter-spacing: 1px; text-align: center;
    
}
.storeBtn
{
    padding:12px 20px ;
    border: 1px solid var(--secondary-color);
    margin-top: 50px;
    background: var(--secondary-color);
    color: #fff;
    transition: 0.5s ease;
}
.storeBtn:hover
{
    background: #fff;
    color: var(--secondary-color);
}