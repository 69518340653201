.Contact
{
   
    
}

#contactPageMap
{
    width: 100vw;
    height: 95vh;
}
.contactWrapper
{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    background: url('../../images/floarl2.png')no-repeat;
    background-blend-mode: multiply;
    background-size: cover;
    background-position: bottom;
    padding: 5vh 0 !important;
    width: 100vw;
}
.formWrapper
{
    position: relative;
}
.formWrapper .topBorder
{
    top: 30px;
}
#contactForm
{
    width: 100%;
    margin: 0 auto;
    
}
.contactPara
{
    width: 70%;
    color: var(--white-text);
    padding-left:  20px; ;
}

.contactDetail
{
    display: flex;
    flex-direction: column;
    margin-top: 4px;
}
.contactDetail>a{
    
    color: var(--white-text);
    margin: 6px 0;
    opacity: 0.6;
    cursor: pointer;
    transition: 0.3s ease;
}
.contactDetail>a:hover
{
    color: var(--secondary-color);
    opacity: 1;
}
.contactDetail>a>i
{
    margin-right: 6px;
    font-size: 140%;
}
.textareaInput

{
    height: 100px !important;
}
.contactSubmitBtn
{
    width: 100%;
    margin-top: 10px !important;
}

@media screen and (max-width:800px)
{
    .contactWrapper .topBorder
    {
        top: 2%;
    }
    .contactWrapper
    {
        flex-direction: column-reverse;
    }
    .contactPara
    {
        width: 100%;opacity: 0.7;
    }
}