.missingSection
{
    position: relative;
    height: 100vh;
    width: 100vw;
    background: url('../../images/background2.jpg') rgba(17, 17, 17, 0.514);
    background-size: cover;
    background-blend-mode: darken;
    background-repeat: no-repeat;
}
.missingDiv
{
    position: relative;
    width: 90%;
    min-height: 70vh;
    color: #fff !important;
}
._404
{
    font-size: 23vh;
    color: #fff ;
}
.missingMessage
{
    text-transform: uppercase;
    letter-spacing: 2px;
    opacity: 0.6;
    animation: flickerAnimation 3s infinite;
    font-size: 16px;
    text-align: center;
}
.MissingBox
{
    width: 120px;
    height: 120px;
    position: absolute;
    border-color: var(--secondary-color);
    border-width: 2px;
}
@keyframes flickerAnimation {
    0%   { opacity:1; }
    50%  { opacity:0.6; }
    100% { opacity:1; }
  }