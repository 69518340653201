@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,700;1,400;1,600;1,700&family=Poppins:ital,wght@0,400;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import './css/util.css';

.cursor,.cursor2
{
  z-index: 10000;
}
.cursor{
  position: fixed;
  width: 45px;
  height: 45px;
  border: 2px solid var(--secondary-color);
  border-radius: 50%;
  left: 0;
  top: 0;
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: .1s;
}

.cursor2{
  position: fixed;
  width: 10px;
  height: 10px;
  background: var(--secondary-color);
  border-radius: 50%;
  left: 0;
  top: 0;
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: .15s;
}

.hovering{
  transform: translate(-50%, -50%) scale(2);
  background-color: var(--secondary-color);
  opacity: .5;
}

.hovering2{
  opacity: 0;
}
@media screen and (max-width:1000px) {
  .cursor,.cursor2
  {
    display: none;
  }
  
}
.blackBrush
{
  pointer-events: none;
}