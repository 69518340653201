@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,700;1,400;1,600;1,700&family=Poppins:ital,wght@0,400;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
:root
{
  --primary-color: #292929;
  /* --primary-color: #fff; */
  --secondary-color: #C19D60;
  /* --secondary-color: #d64e4e; */
  --white-text:#fff;
  /* --white-text:#111; */
  /* --dark-text:#fff; */
  --dark-text:#111;
  
  --brush-filter:brightness(124%);
  --serif-font:'Playfair Display', serif;
  --sans-serif-font:'Playfair Display', serif;
}

*
{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
 
}
.row
{
  margin: 0 !important;
  padding: 0 !important;
}
html
{
  
  scroll-behavior: smooth;
  
}
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #292929;
  background: var(--primary-color);
  max-width: 100vw !important;
  overflow-x:hidden !important;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a,a:active,a:hover,a:focus
{
  text-decoration: none;
  
  
}
a:focus
{
  color: #C19D60;
  color: var(--secondary-color);
}
input:focus{
  outline: 0 !important;
  
  box-shadow: none;
}
.dots
{
  color: #C19D60 !important;
  color: var(--secondary-color) !important;
}
.none
{
  display: none;
}
.h-fscreen
{
    min-height: 100vh;
}
.flex

{
    display: flex;
}
.center
{
    display: flex;
    align-items: center;
    justify-content: center;
}

.column
{
    flex-direction: column;
}
.serif
{
    font-family: var(--serif-font);
    color: var(--white-text);
}

/* LOADER */
.preloader
{
    background: var(--primary-color);
    z-index: 99999;
    position: fixed;
    width: 100vw;
    height: 100vh;
}
.loader1 {
    display:inline-block;
    font-size:0px;
    padding:0px;
 }
 .loader1 span {
    vertical-align:middle;
    border-radius:100%;
    
    display:inline-block;
    width:20px;
    height:20px;
    margin:3px 2px;
    -webkit-animation:loader1 0.8s linear infinite alternate;
    animation:loader1 0.8s linear infinite alternate;
 }
 .loader1 span:nth-child(1) {
    -webkit-animation-delay:-1s;
    animation-delay:-1s;
   background:#c19d608a;
 }
 .loader1 span:nth-child(2) {
    -webkit-animation-delay:-0.8s;
    animation-delay:-0.8s;
   background:#c19d60a4;
 }
 .loader1 span:nth-child(3) {
    -webkit-animation-delay:-0.26666s;
    animation-delay:-0.26666s;
   background:#C19D60;
 }
 .loader1 span:nth-child(4) {
    -webkit-animation-delay:-0.8s;
    animation-delay:-0.8s;
   background:#c19d60b4;
   
 }
 .loader1 span:nth-child(5) {
    -webkit-animation-delay:-1s;
    animation-delay:-1s;
   background:#c19d6052;
 }
 
 @keyframes loader1 {
    from {-webkit-transform: scale(0, 0);transform: scale(0, 0);}
    to {-webkit-transform: scale(1, 1);transform: scale(1, 1);}
 }
 @-webkit-keyframes loader1 {
    from {-webkit-transform: scale(0, 0);}
    to {-webkit-transform: scale(1, 1);}
 }

.AlternateWrapper
{
    min-height: 70vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
 .AlternateDetails

 {
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
 }
 .AlternateContent
 {
     color: var(--secondary-color);
     font-size: 28px;
     text-align: center;
 }
 .AlternateHeading{
     color: #fff;
     width: -webkit-max-content;
     width: max-content;
     font-size: 6vh;
     font-weight: 700;
     margin-top: 12px;
 }
 input
 {
     color: var(--secondary-color) !important;
 }
.cursor,.cursor2
{
  z-index: 10000;
}
.cursor{
  position: fixed;
  width: 45px;
  height: 45px;
  border: 2px solid var(--secondary-color);
  border-radius: 50%;
  left: 0;
  top: 0;
  pointer-events: none;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  transition: .1s;
}

.cursor2{
  position: fixed;
  width: 10px;
  height: 10px;
  background: var(--secondary-color);
  border-radius: 50%;
  left: 0;
  top: 0;
  pointer-events: none;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  transition: .15s;
}

.hovering{
  -webkit-transform: translate(-50%, -50%) scale(2);
          transform: translate(-50%, -50%) scale(2);
  background-color: var(--secondary-color);
  opacity: .5;
}

.hovering2{
  opacity: 0;
}
@media screen and (max-width:1000px) {
  .cursor,.cursor2
  {
    display: none;
  }
  
}
.blackBrush
{
  pointer-events: none;
}
.button {
    display: inline-block;
    position: relative;
  }
 
  .button.is-play {
    background-color: rgba(255, 255, 255, 0.719);
    border-radius: 50%;
    width: 80px;
    height: 80px;
  }
  .button:hover>.button.is-play
  {
      background: #fff !important;
      transition: 0.5s ease;
  }
  .button.is-play .button-outer-circle {
    background: rgb(255, 255, 255);
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
  }
  .button.is-play .button-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .button.is-play .button-icon .triangle {
    -webkit-animation: fadeIn 7s ease;
            animation: fadeIn 7s ease;
  }
  .button.is-play .button-icon .path {
    stroke-dasharray: 90;
    stroke-dashoffset: 0;
    -webkit-animation: triangleStroke 3s;
            animation: triangleStroke 3s;
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease;
  }
  .has-scale-animation {
    -webkit-animation: smallScale 3s infinite;
            animation: smallScale 3s infinite;
  }
  .has-delay-short {
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
  }
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-webkit-keyframes triangleStroke {
    from {
      stroke-dashoffset: 90;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
  @keyframes triangleStroke {
    from {
      stroke-dashoffset: 90;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
  @-webkit-keyframes smallScale {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
    to {
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
      opacity: 0;
    }
  }
  @keyframes smallScale {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
    to {
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
      opacity: 0;
    }
  }
  
/* .preloader
{
    animation: remove 3s ;
}
@keyframes remove
{
    from {
        opacity: 1;
    }
    to
    {
        opacity: 0;
        
    }
} */
.landingWrapper
{
    /* background: url('../../images/background2.jpg') rgba(17, 17, 17, 0.527); */
    /* background-size: cover;
    background-blend-mode: darken;
    background-attachment: fixed;
    background-repeat: no-repeat; */
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 10vw;
    overflow: hidden;
    position: relative;
}

.landingDetails
{
color: var(--white-text);
height: -webkit-max-content;
height: max-content;
}
.dots
{
    font-size: 31px;
    font-weight: 100;
    margin-bottom: 12px;
    color: #fff;
    font-style: italic;
    letter-spacing: 2px;
}
.landingContent
{
    font-family: var(--serif-font);
    font-size: 21px;
    font-style: italic;
    color: var(--secondary-color);
}
.landingHeading
{
    width: 90%;
    font-size: 8vh;
    font-weight: 800;
    color: #fff;
}
.landingBtn
{
    border: 1px solid var(--secondary-color);
    color: #fff;
    background: rgba(112, 111, 111, 0.137);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 12px 20px;
    font-weight: 600;
    letter-spacing: 1px;
    font-family: var(--serif-font);
    margin-top: 40px;
    font-style: normal;
}
.landingBtn:hover
{
    background: #fff;
    color: var(--secondary-color);
    transition: 0.5s ease;
}
.landingBtn>span
{
    margin-right: 12px;
}
/* .mobileBackground
{
    
    background: url('../../images/backgroundmobile.jpg') rgba(17, 17, 17, 0.589) !important;
    background-size: cover;
    background-position: center;
    background-blend-mode: darken;
    background-attachment: fixed;
    background-repeat: no-repeat;
    min-height: 130vh;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 0 10vw;
    padding-bottom: 10vh;
} */

@media screen and (max-width:800px) {
    .landingDetails
    {
        margin-top: 60px;
    }
    .landingHeading
    {
        font-size: 7vh;
    }
    .landingWrapper
{
    background: url(/static/media/backgroundmobile.9c6f777c.jpg) rgba(17, 17, 17, 0.589);
    background-size: cover;
    background-position: center;
    background-blend-mode: darken;
    background-attachment: scroll;
    background-repeat: no-repeat;
    min-height: 130vh;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    justify-content: center;
    padding: 0 10vw;
    padding-bottom: 0vh;
}
.buttonContainer
{   position: relative;
    top: 0;
    margin: 0 auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
}
#landingSlider
{
    display: none;
    width: 100%;
    
}
}


.promoVideo
{
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: #111;
    z-index: 99999;
     display: none;
   
    align-items: center;
    justify-content: center;
    transition: 0.5s ease;
    transition-delay: 1s;
}
.promoVideo>iframe
{
    width: 70vw;
    height: 70vh;
}
.closeVideo
{
    position: absolute;
    top: 2%;
    right: 4%;
    color: #fff;
    font-size: 6vh;
    cursor: pointer;
   
}


#landingSlider
{
    width: 100%;
    min-height: 100vh;
    position: absolute;
}
.carousel
{
    position:static;
    top: 0;
}
.carousel-item
{
    transition: 3s ease !important;
}
.carousel-control
{
    opacity: 0;
}
.landing_background
{
    width: 100vw;
    height: 100vh;
    position: relative;
}
.landing_background>img
{
    width: 110%;
    height: 100%;
    position: relative;
    object-fit: cover;
    object-position: center;
}
.overlay
{
    position: absolute;
    content: ' ';
    width: 100%;
    height: 100%;
    background: rgba(41, 41, 41, 0.596);
    top: 0;
    left: 0;
}
/* .carousel-control
{
    background: var(--primary-color);
    font-size: 30px;
    opacity: 1;
    width: fit-content;
    height: fit-content;
    border-radius: 50%;
    padding:12px 20px;
    padding-left: 18px;
} */

@media screen and (max-width:800px) {
    #landingSlider
    {
        width: 100%;
        
    }
    
}
.brushImageDiv
{
    width: 100vw;
    height: 60px;
    position: relative;

}
.brushImage
{
    width: 100%;
    height: 100%;
    position: absolute;
    top: -100%;
    -webkit-filter: brightness(124%);
            filter: brightness(124%);
}

.aboutBrush,#contactBrush
{
    width: 100vw;
    height: 150px;

    background: url(/static/media/blackBrush.f72ba0fe.png);
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden !important;
    margin-top: -30px;
    /* transform: rotate(180deg); */
    -webkit-filter: var(--brush-filter);
            filter: var(--brush-filter);
    background-position: top;
}

@media screen  and (max-width:1100px){

   .aboutBrush
   {
       /* background-size:cover ; */
       margin-top: -20px;
   } 
}
@media screen  and (max-width:600px){

    .aboutBrush
    {
        /* background-size:cover ; */
        margin-top: -10px;
    } 
 }
.about
{   position: relative;
    padding: 10vh 10vw;
    overflow: hidden !important;
    background: var(--primary-color);
}
.topBorder
{
    width: 100px;
    height: 100px;
    background: transparent;
    position: absolute;
    top: 5%;
    left: 5%;
    border-top: 1px dotted var(--secondary-color);
    border-left: 1px dotted var(--secondary-color);
}
.about>.row
{
    justify-content: space-between;
}
.about>.row>div

{
    margin-top: 40px;
}
.aboutImg
{
    width: 100%;
    height: auto;
}
.aboutDetail
{
    color: var(--white-text);
    position: relative;
}
.aboutDetail>div>span
{
    color: var(--secondary-color);
    font-style: italic;
    font-size: 18px;
}
.aboutDetail span,.aboutDetail h3
{
    
    font-family:var(--serif-font);
}
.aboutDetail h3
{
    color: var(--white-text);
    font-size: 28px;
    font-weight: 600;
}
.aboutDetail p
{
    color: var(--white-text);
    font-size: 16px;
}
.aboutImg:nth-of-type(1)
{
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.548) 0px 3px 8px;
    border: 1px dotted var(--secondary-color);
    /* background: var(--primary-color); */
    padding: 8px;
    
}

.aboutImg:nth-of-type(2)
{
    position: absolute;
    left: 95%;
    z-index: 0;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.borderBox
{   
    height: 100%;
    width: 100%;
    position: absolute;
    top: 20%;
    right: 0;
    z-index: -1;
    background: transparent;
    border: 1px dotted var(--secondary-color);
}
.aboutBtn
{
    color: var(--white-text);
}
.aboutBtn:hover
{
    background: var(--secondary-color);
    color: var(--dark-text);
}
.spiceRow
{
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    padding: 5vh 0;
}
#spiceImg
{
    position: absolute;
    left: -95% !important;
    top: 5%;
}
@media screen and (max-width:800px) {

    .aboutImg:nth-of-type(2),#spiceImg
{
    display: none;
}
}


.AboutWrapper
{
    background: url(/static/media/DSC_9552.eabeb16c.jpg) rgba(17, 17, 17, 0.438);
    background-size: cover;
    background-attachment: fixed;
    background-blend-mode: darken;
  
}
.aboutParaWrapper
{
    margin: 30px 0;
}
.aboutTeam
{
    background: rgb(0, 0, 0) !important;
}
.aboutTeam .bigText
{
    color: #fff !important;
}
.openingHours
{
    position: relative;
    padding: 10vh 0;
    background: var(--primary-color);
}
.openingHours>.row
{
    align-items: flex-start;
}
.openingWrapper
{
    min-height: 80vh;
    padding: 20px 0;
    

}
.quoteDiv
{
    background: url(/static/media/DSC_9552.eabeb16c.jpg) rgba(29, 29, 29, 0.788);
    background-blend-mode: overlay !important;
    background-size: cover !important;
    /* background-attachment: fixed; */
    background-position: right;
    background-position: center !important;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
   
}


.quoteWrapper
{   height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
}

#quoteIcon
{
    color: transparent;
    -webkit-text-stroke: 1px;
    -webkit-text-stroke-color: var(--secondary-color);
    font-weight: 800;
    font-size: 5vh;
}

.quote
{
    width: 90%;
    text-align: center;
    color: #fff;
    font-family: var(--serif-font);
    margin-top: 10px;
    letter-spacing: 1px;
    text-shadow: black 0.1em 0.1em 0.2em
}
.designation
{   width: 90%;
    margin: 50px auto;
    font-size: 19px;
    font-weight: 700;
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    justify-content: space-around;
    text-align: center;
}
.designation>img,.designation>span
{
    margin-top: 2%;
}
.hourDiv
{
    background: url(/static/media/floarl2.db76fb3b.png) rgba(104, 104, 104, 0.479);
    background-size: cover;
    background-blend-mode: normal;
    background-position: left;
    -webkit-backdrop-filter: contrast(100%);
            backdrop-filter: contrast(100%);
}


.hourWrapper
{
    width: 90%;
    height: 90%;
    background: rgba(17, 17, 17, 0.534);
    border: 1px dotted var(--secondary-color);
    padding: 20px 0;
}
.hourWrapper>div>span
{
    color: var(--secondary-color);
}
.hourWrapper>div>span:nth-of-type(1)
{
    font-style: italic;
    font-size: 18px;
}
.hourWrapper>div>h3
{
    font-size: 38px;
    font-weight: 700;
    color: #fff;
}
.timingWrapper
{
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    align-items: flex-start;
    justify-content: space-evenly;
    
}
.timingDiv
{   
    color: #fff;
    margin-top: 30px;
}
.timingDiv:nth-of-type(1)::after
{
    content: '';
    position: absolute;
    height: 100%;
    width: 2px;
    background: var(--secondary-color);
    right: 0;
}
.timingDay
{
    color: var(--secondary-color);
}
.timing
{
    font-size: 30px;
    font-weight: 700;
    position: relative;
    margin: 7px 0;
}
.timing:nth-of-type(2n+1)::after
{
    content: '';
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 0.15px;
    background: var(--secondary-color);
}
.reservationBtn
{
    margin: 40px auto;
    text-align: center;
    padding: 12px 20px;
    font-size: 4vh;
    border: 1px solid var(--secondary-color);
    transition: 0.3s ease;
    cursor: pointer;
    color: #fff !important;
}
.reservationBtn:hover
{
    color: var(--secondary-color);
    
}

@media screen and (max-width:900px) {
    .timingDiv:nth-of-type(1)::after
    {
        display: none;
    }
}
@media screen and (max-width:480px) {
    .timingDiv:nth-of-type(1)::after
    {
        display: none;
    }
    .timing:nth-of-type(2n+1)::after
    {
        display: none;
    }
}
.title
{
    text-transform: capitalize;
}
.smallText
{
    font-style: italic;
    color: var(--secondary-color);
    font-size: 18px;
    text-align: center;
}
.bigText
{
    font-size: 28px;
    text-align: center;
}
  .signatureDishes
  {

        padding-bottom: 5vh ;
        position: relative;
        background: url(/static/media/floarl2.db76fb3b.png) var(--primary-color);
        background-blend-mode: normal;
      
        background-size: cover;
        background-repeat: no-repeat;
  }

  .box-item {
    position: relative;
    -webkit-backface-visibility: hidden;
    width: 415px;
    margin-bottom: 35px;
    max-width: 100%;
    
  }
  
  .flip-box {
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    perspective: 1000px;
    -webkit-perspective: 1000px;
    
  }
  
  .flip-box-front,
  .flip-box-back {
    background-size: cover !important;
    background-position: center !important;
    background-blend-mode: darken !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 8px;
    min-height: 475px;
    transition: -webkit-transform 0.7s cubic-bezier(.4,.2,.2,1);
    transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
    transition: transform 0.7s cubic-bezier(.4,.2,.2,1), -webkit-transform 0.7s cubic-bezier(.4,.2,.2,1);
    -webkit-transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-box-front {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  
  .flip-box:hover .flip-box-front {
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  
  .flip-box-back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  
  .flip-box:hover .flip-box-back {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  
  .flip-box .inner {
    position: absolute;
    left: 0;
    width: 100%;
    padding: 60px;
    outline: 1px solid transparent;
    -webkit-perspective: inherit;
    perspective: inherit;
    z-index: 2;
    color: #fff;
    transform: translateY(-50%) translateZ(60px) scale(.94);
    -webkit-transform: translateY(-50%) translateZ(60px) scale(.94);
    -ms-transform: translateY(-50%) translateZ(60px) scale(.94);
    top: 50%;
  }
  
  .flip-box-header {
    font-size: 34px;
    color: #fff;
  }
  
  .flip-box p {
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 1.5em;
    text-transform: uppercase;
    font-family: Poppins,sans-serif;
    color: #fff;
  }
  
  .flip-box-img {
    margin-top: 25px;
  }
  
  .flip-box-button {
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    margin-top: 25px;
    padding: 15px 20px;
    text-transform: uppercase;
    
  }

  .signatureCardWrapper
  {
   display: flex;
   flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .signatureCardWrapper .Box
  {
      width: 70px;
      height: 70px;
      position: absolute;
      
      
  }
  .borderBoxTop
  {
    top: 4%;
    left: 4%;
    border-top: 1px dotted #eee;
    border-left: 1px dotted #eee;
  }
  .borderBoxBottom
  {
      bottom: 4%;
      right: 4%;
      border-bottom: 1px dotted #eee;
      border-right: 1px dotted #eee;
  }
  .CardBottom
  {
      position: absolute;
      bottom: 3%;
      left: 3%;
      font-family: var(--serif-font);
      font-size: 21px;
      color: #fff;
  }
  .signatureBtn
  {
    color: var(--white-text);
  }
  .signatureBtn:hover
  {
    background: var(--secondary-color);
  }
  @media screen and (max-width:480px) {
      .box-item
      {
          max-width: 100vw;
      }
     .hourDiv
     {
         background-size: contain;
         background-repeat: no-repeat;
         padding: 10vh 0;
     }
      
  }
.menuBrush
{
    width: 100vw;
    height: 200px;
    position: absolute;
    background: url(/static/media/blackBrush.f72ba0fe.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    overflow: hidden !important;
    pointer-events: none;
    -webkit-filter: brightness(124%);
            filter: brightness(124%);
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    margin-top: -5px;

}
.menuBrushBottom
{
    width: 100vw;
    height: 200px;
    position: absolute;
    background: url(/static/media/blackBrush.f72ba0fe.png);
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden !important;
    pointer-events: none;
    -webkit-filter: brightness(124%);
            filter: brightness(124%);
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    background-position:bottom;
    margin-top: -3%;
}
@media screen and (max-width:1100px) {
    .menuBrush
    {
        margin-top: 0;
    }
}

.menuSmall
{
    background: url(/static/media/background2.f073e96e.jpg) rgba(0, 0, 0, 0.822);
   
    background-size: cover;
    background-blend-mode: darken !important;
    background-position: center;
    background-attachment: fixed;
    padding: 5vh 0;
    color: rgba(255, 255, 255, 0.205);
}


.menuCardWrapper
{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    max-width: 90vw;
    margin: 0 auto;
}

.menuCard
{
    margin: 20px ;
 
    padding: 0 !important;
    position: relative;
    overflow: hidden;
    transition: 0.5s ease !important;
    border-bottom: 1px solid var(--white-text);
    box-shadow: rgba(0, 0, 0, 0.589) 0px 3px 8px;
}
.menuCardDetails
{
    position: absolute;
    background: var(--white-text);
    bottom: 0;    
    width: 100%;
    padding: 12px 20px;
    border: 1px solid var(--white-text);
    border-bottom: transparent;    
    -webkit-transform: translateY(60%);    
            transform: translateY(60%);
    transition: 0.5s ease;
    min-height: 200px;
    
}

.menuImage
{
    width: 100% !important;
    /* height: 400px; */
    overflow: hidden;
}

.menuImage>img
{
    width: 100%;
    height: auto;
    object-position: center;
    transition: 0.5s ease;
}
.menuCard:hover .menuImage>img
{
    -webkit-transform: translateY(-20%) scale(1.05);
            transform: translateY(-20%) scale(1.05);
}
.menuCardTop
{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
}
.menuCardTop>div
{
    padding: 0 !important;
}
.menuCardTop>col-xs-4
{
   text-align: right;
}
.recipeName,.recipeType
{
    color: var(--primary-color);
}
.recipeName
{
    font-size: 110%;
    font-weight: 600;
    
}
.recipeType
{
    margin-top: 6px;
}
.veg
{
    color: green;
}
.nonveg
{
    color: red;
}
.recipePrice
{
    color: var(--secondary-color);
    font-size: 21px;
    font-weight: 600;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: center;
}
.recipePrice>img
{
    width: 20px;
    height: 20px;
    margin-right: 3px;
}
.menuCardDesc
{
    margin-top: 20px;
    color: var(--primary-color);
    opacity: 0.7;
/*     
    height: 0;
    overflow: hidden; */
}
.menuCard:hover .menuCardDetails
{   
    
    -webkit-transform: translateY(0);   
    
            transform: translateY(0);

}


.MenuWrapper
{
    background: url(/static/media/1.de1ad5c8.jpg) rgba(0, 0, 0, 0.555);
    background-size: cover;
    background-blend-mode: darken !important;
    background-position: 8%;
    background-attachment: fixed;
}
@media screen and (max-width:1000px) {
    .menuCardDetails
    {
        position: relative;
        -webkit-transform: translateY(0);
                transform: translateY(0);
        border: transparent;
    }
    .menuCard
    {
        border: 1px solid var(--white-text);
    }
    
}
@media screen and (max-width:800px) {
    .menuCard:hover .menuImage>img
{
    -webkit-transform: translateY(0) scale(1.05);
            transform: translateY(0) scale(1.05);
}
}
.specialMenu
{
    border: 1px solid #fff;
}
.specialMenu .menuCardDetails
{
    background: #fff ;
    border-color: transparent;
    /* border-top: 1px solid #fff; */

}
.specialMenu .recipeName,.specialMenu .menuCardDesc
{
    color: #292929 ;
}
.Contact
{
   
    
}

#contactPageMap
{
    width: 100vw;
    height: 95vh;
}
.contactWrapper
{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    background: url(/static/media/floarl2.db76fb3b.png)no-repeat;
    background-blend-mode: multiply;
    background-size: cover;
    background-position: bottom;
    padding: 5vh 0 !important;
    width: 100vw;
}
.formWrapper
{
    position: relative;
}
.formWrapper .topBorder
{
    top: 30px;
}
#contactForm
{
    width: 100%;
    margin: 0 auto;
    
}
.contactPara
{
    width: 70%;
    color: var(--white-text);
    padding-left:  20px; ;
}

.contactDetail
{
    display: flex;
    flex-direction: column;
    margin-top: 4px;
}
.contactDetail>a{
    
    color: var(--white-text);
    margin: 6px 0;
    opacity: 0.6;
    cursor: pointer;
    transition: 0.3s ease;
}
.contactDetail>a:hover
{
    color: var(--secondary-color);
    opacity: 1;
}
.contactDetail>a>i
{
    margin-right: 6px;
    font-size: 140%;
}
.textareaInput

{
    height: 100px !important;
}
.contactSubmitBtn
{
    width: 100%;
    margin-top: 10px !important;
}

@media screen and (max-width:800px)
{
    .contactWrapper .topBorder
    {
        top: 2%;
    }
    .contactWrapper
    {
        flex-direction: column-reverse;
    }
    .contactPara
    {
        width: 100%;opacity: 0.7;
    }
}
.TeamWrapper
{
    /* background: var(--primary-color) ; */
}
.TeamWrapper
{
    position: relative;
    width: 100%;
    padding-top: 5vh;
}
@media screen and (max-width:800px) {
    .TeamWrapper>.topBorder
    {
        display: none;
    }
    
}
.teamCardWrapper
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.teamCard
{
    background: var(--dark-text);
    padding: 0;
    margin: 20px;
   
}

.teamImage
{
    width: 100%;
    height: 250px;
    position: relative;
    overflow: hidden;
   
}
.teamImage>img
{
    width: 100%;
    height:100%;
    object-fit: cover;
    -webkit-filter: brightness(80%);
            filter: brightness(80%);
}
.TeamBox
{
    position: absolute;
    height: 50px;
    width: 50px;
}
.teamCardDetails
{   padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px -1px 4px -2px var(--white-text) inset;
    border-top: transparent;
}
.teamImage>.borderBoxTop
/* ,.teamImage>.borderBoxBottom */
{
    border-top: 2px dotted var(--secondary-color);
    border-left: 2px dotted var(--secondary-color);
}
.teamImage>.borderBoxBottom
{
    border-right: 2px dotted var(--secondary-color);
    border-bottom: 2px dotted var(--secondary-color);
}
.teamCardDesignation
{
    color: var(--secondary-color);

}
.teamCardpara
{
    color: var(--white-text);
    width: 80%;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.5px;
}

.teamCard .dots
{
    color: var(--secondary-color);
}
.form-row
{
    margin: auto !important;
    padding:0;
}
.reservationModal
{
    width: 100vw;
    height: 100vh;
    background: rgba(17, 17, 17, 0.932);
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: scroll;
    display: flex;
    align-items: center;
    justify-content: center;
    /* backdrop-filter: blur(14px); */
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
    pointer-events: none;
    transition: 0.4s ease;
    

}

.openModal
{
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
    pointer-events: all;
    transition: 0.4s ease;
}
.closeBtn
{   position: absolute;
    top: 12%;
    right: 12%;
    font-size: 30px;
    border: 1px solid var(--secondary-color);
    padding: 6px 20px;
    color: #fff;
    transition: 0.4s ease;
    background: #292929;
}
.closeBtn:hover
{
    background: var(--secondary-color);
    border: 1px solid #fff;
    
}
.reservationFormWrapper
{
    width: 60vw;
    height: 70vh;
    border: 1px solid #fff;
    background: #57575779;
}
#reservationForm
{
    width: 90%;
    height: 90%;    
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    margin: 0 auto;
    overflow-y: scroll;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 10vh;
    background: url(/static/media/floarl2.db76fb3b.png);
    background-size: cover;
    
}
#reservationForm::-webkit-scrollbar
{
    display: none;
}
@media screen and (max-width:480px) {

    .reservationFormWrapper
   {
       width: 90vw;
       height: 90vh;
   } 
   .closeBtn
   {
       top: 2%;
       right: 2%;
   }
}
::-webkit-input-placeholder
{
    color: #fff !important;
}
:-ms-input-placeholder
{
    color: #fff !important;
}
::placeholder
{
    color: #fff !important;
}
#reservationForm>.form-row
{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  
}
.reservationInput
{
    background: #292929;
    height: 50px;
    padding: 12px;
    margin: 10px 0;
    width: 100%;
    color: #fff;
    outline: none;
    border: 1px solid #eee;
    transition: 0.4s ease;
    
}
.reservationInput:focus
{
    outline: none;
    border-color: var(--secondary-color);
    
}

.modalBtn
{
    margin-top: 15px !important;
    width: 100%;
    font-size: 22px;
    color: #fff !important;
}
.modalBtn:hover
{
    color: var(--secondary-color) !important;
}
.event
{
    color: #fff;
    text-align: center;
}
.eventBtn
{   padding:12px 20px ;
    border: 1px solid var(--secondary-color);
    background:#292929;
    color: #fff;
    transition: 0.5s ease;
    font-size: 18px;
    margin-top: 50px;
}
.eventBtn:hover
{
    background: #fff;
    color: #292929;
}

.storeDiv
{
    background: url(/static/media/DSC_0107.b5cfc78a.jpg) no-repeat rgba(17, 17, 17, 0.637);
    background-blend-mode: darken;
    background-position: center;
    /* background-attachment: fixed; */
    background-size: cover;
}
.storeWrapper>div>h3
{
    color: var(--secondary-color);
    font-size: 5vh;
}
.storeWrapper>div>span
{
    color: #fff;
    letter-spacing: 1px; text-align: center;
    
}
.storeBtn
{
    padding:12px 20px ;
    border: 1px solid var(--secondary-color);
    margin-top: 50px;
    background: var(--secondary-color);
    color: #fff;
    transition: 0.5s ease;
}
.storeBtn:hover
{
    background: #fff;
    color: var(--secondary-color);
}
.loader-wrap {
	position: fixed;
	top:0;
	bottom:0;
	left:0;
	right:0;
	overflow:hidden;
	z-index:100000000;
}
.loader {
	position: absolute;
	top:50%;
	left:50%;
	width:40px;
	height:40px;
	margin:-20px 0 0 -20px;
	z-index:20;
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
}
.loader:before{
	content:'';
	position:absolute;
	top:50%;
	left:50%;
	width:40px;
	height:40px;
	margin:-20px 0 0 -20px;
	border: 2px solid var(--secondary-color);
	border-top: 2px  solid;
	border-radius: 50%;
	-webkit-animation: spin2 0.5s infinite linear;
	        animation: spin2 0.5s infinite linear;
	border-radius:100%;
	z-index:1;
}
@-webkit-keyframes spin2 {
	from {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(359deg);
		        transform: rotate(359deg);
	}
}
@keyframes spin2 {
	from {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(359deg);
		        transform: rotate(359deg);
	}
}
.cd-tabs-layer  , .cd-reserv-overlay-layer {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	height: 100%;
	width: 100%;
	opacity: 0;
	visibility: hidden;
	overflow: hidden;
}
.cd-loader-layer {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	height: 100%;
	width: 100%;
	opacity: 1;
	visibility: visible;
	overflow: hidden;
}
.cd-loader-layer .loader-layer , .cd-tabs-layer .tabs-layer , .cd-reserv-overlay-layer .reserv-overlay-layer {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translateY(-50%) translateX(-98%);
	        transform: translateY(-50%) translateX(-98%);
	height: 100%;
	width: 2500%;
	background: url(/static/media/ink2.1e23a94b.png) no-repeat 0 0;
	background-size: 100% 100%;
}
.cd-tabs-layer.visible, .cd-loader-layer.visible , .cd-reserv-overlay-layer.visible {
	opacity: 1;
	visibility: visible;
}
.cd-tabs-layer.opening .tabs-layer , .cd-loader-layer.opening .loader-layer , .cd-reserv-overlay-layer.opening .reserv-overlay-layer  {
	-webkit-animation: cd-sequence 0.8s steps(24);
	        animation: cd-sequence 0.8s steps(24);
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}
.cd-tabs-layer.closing .tabs-layer   , .cd-reserv-overlay-layer.closing .reserv-overlay-layer {
	-webkit-animation: cd-sequence-reverse 0.8s steps(24);
	        animation: cd-sequence-reverse 0.8s steps(24);
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}
.cd-loader-layer.closing .loader-layer{
	-webkit-animation: cd-sequence-reverse 0.8s steps(24);
	        animation: cd-sequence-reverse 0.8s steps(24);
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
} 
@-webkit-keyframes cd-sequence {
  0% {
    -webkit-transform: translateY(-50%) translateX(-2%);
            transform: translateY(-50%) translateX(-2%);
  }
  100% {
    -webkit-transform: translateY(-50%) translateX(-98%);
            transform: translateY(-50%) translateX(-98%);
  }
} 
@keyframes cd-sequence {
  0% {
    -webkit-transform: translateY(-50%) translateX(-2%);
            transform: translateY(-50%) translateX(-2%);
  }
  100% {
    -webkit-transform: translateY(-50%) translateX(-98%);
            transform: translateY(-50%) translateX(-98%);
  }
}
@-webkit-keyframes cd-sequence-reverse {
  0% {
    -webkit-transform: translateY(-50%) translateX(-98%);
            transform: translateY(-50%) translateX(-98%);
  }
  100% {
    -webkit-transform: translateY(-50%) translateX(-2%);
            transform: translateY(-50%) translateX(-2%);
  }
}
@keyframes cd-sequence-reverse {
  0% {
    -webkit-transform: translateY(-50%) translateX(-98%);
            transform: translateY(-50%) translateX(-98%);
  }
  100% {
    -webkit-transform: translateY(-50%) translateX(-2%);
            transform: translateY(-50%) translateX(-2%);
  }
}
#contactBrush
{
  -webkit-filter: brightness(104%);
          filter: brightness(104%);
}
.GalleryWrapper
{
    background: url(/static/media/background2.f073e96e.jpg) rgba(17, 17, 17, 0.616);
    background-blend-mode: darken;
    background-size: cover;
    background-attachment: fixed;
    width: 100vw;
    margin: 0 auto;
}
.Galleryrow {
    width: 96vw;
    margin: 0 auto; /* IE10 */
    display: flex; /* IE10 */
    flex-wrap: wrap;
    padding: 5vh 4px;
    align-items: center;
    justify-content: center;
  }
  
  /* Create four equal columns that sits next to each other */
  .imageColumn { /* IE10 */
    flex: 25% 1;
    max-width: 25%;
    padding: 0 4px;
  }
  
  .imageColumn img {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
  }
  
  /* Responsive layout - makes a two column-layout instead of four columns */
  @media screen and (max-width: 800px) {
    .imageColumn {
      flex: 50% 1;
      max-width: 50%;
    }
  }
  

  .imageWrapper
  {
    overflow: hidden;
    width: 96%;
    height: 90%;
    margin: 12px auto;
    background: var(--primary-color);
    border: 5px solid transparent;
    transition: 0.5s ease;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; */
  }
  .imageWrapper>img
  {
      -webkit-filter: brightness(0.9);
              filter: brightness(0.9);
    transition: 0.5s ease;
    object-fit: cover;
  }
  .imageWrapper:hover
  {
    border-color: var(--secondary-color);
  }
 .imageWrapper>img:hover
  { -webkit-filter: brightness(1); filter: brightness(1);
      -webkit-transform: scale(1.05);
              transform: scale(1.05);
      
  }
.navSection
{
width: 80vw;
position: fixed;
margin: 0 10vw;
z-index: 999;
top: 0;
transition: 0.5s ease;
}

.topWrapper
{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    
    width: 100%;
    margin: 0 auto;
    padding: 15px 0;
    padding-bottom: 12px;
  
}
.topbarDetailWrapper
{
    margin: 0 12px;
}
.topbarDetailWrapper>span
{
    font-family: var(--serif-font);
    color: #fff;
}
.topbarDetailWrapper>.type
{
    color: var(--secondary-color);
    font-weight: 700;
    margin-right: 6px;
}

.navBar
{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0.15px solid var(--white-text);
    border-right: transparent;
    border-radius: 10px;
    background: var(--primary-color);
    height: 80px;
    padding: 0 40px;
    padding-right: 0;
    transition: 0.4s ease;
}
.navBrand
{
    height: 80%;
   
}
.navBrand>img
{
    height: 100%;
    width: auto;
    image-rendering: optimizeQuality;
}
.navMenu
{
    display: flex;
    align-items: center;
}
.navItem
{
    color: var(--white-text);
    margin: 0 12px;
    font-size: 14px;
    font-weight: 600;
    transition: 0.5s ease;
}
.active,.navItem:hover
{
    color: var(--secondary-color);
}
.navRight
{
    display: flex;
    align-items: center;
    justify-content: space-between;
   position: relative;
    right: 0;
}
.hamMenu
{
    display: none;
}

.lightDarkButton
{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border:1px solid var(--secondary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white-text);
    background: var(--secondary-color);
    height:80px;
    flex: 0.5 1;
    padding: 0 20px;
    font-size: 3vh;
    position: relative;
    margin-left: 20px;
   transition: 0.5s ease;
}
.lightDarkButton>i
{
    width: 20px;
}
@media screen and (max-width:800px) {
    .topWrapper
    {
        justify-content: space-around;
        padding-top:10px;
        padding-bottom: 0;
    }
    .hamMenu
    {
        display: flex;
        flex-direction: column;
    }
    #closeBtn
    {
        display: none;
    }
    .hamMenu>span
    {
        width: 30px;
        height: 2px !important;
        background: var(--white-text);
        margin: 4px;
        transition: 0.3s ;
    }  

    .navMenu
    {   
        display: none;
        flex-direction: column;
        position: absolute;
        top: 100%;
        right: 0;
        width: 30%;
        align-items: flex-start;
        background: var(--primary-color);
        padding: 12px;
        height: 0;
        
    }
    .open
    {
        height: auto;
        display: flex;
        transition: 0.5s ease !important;
    }
    .navItem
    {
        font-size: 18px;
        padding: 5px 0;
        margin-bottom: 7px;
    }
    
}

@media screen and (max-width:480px) {
    
    .navSection
    {
        width: 90vw;
        margin: 0 5vw;
    }
    .navBar
    {
        padding-left: 10px;
        padding-right: 0;
    }
    .topWrapper
    {
        /* flex-direction: column;
        align-items: center;
        justify-content: center; */
        font-size: 12px;
    }
    .navMenu
    {
        width: -webkit-max-content;
        width: max-content;
        position: absolute;
        padding: 40 80;
    }
    .navItem
    {
        /* border-bottom:1px solid var(--secondary-color); */
        width: 100%;
        text-align: left;
        
    }
    .navBar
    {
        margin-top: 10px;
    }
}

.Footer,.copyright
{
    padding: 10vh 5vw;
    width: 100vw;
    background: #111;
    /* position: fixed; */
    bottom: 0;
    left: 0;
    
}
.footerLogo
{
    width: 100px;
    height: auto;
}
.footerSocials
{
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
 
    padding: 12px 20px;
}
.FooterTopBar
{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
} 
@media screen and (max-width:480px)
{
    .FooterTopBar
    {   flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .footerSocials
    {
        margin-top: 20px;
        padding: 0;
    }
    .FooterContent
    {
        display: block;
    }
  

}
.FooterTopBar::after
{
    content: '';
    position: relative;
    width: 90%;
    margin: 30px auto;
    height: 1px;
    background: rgba(238, 238, 238, 0.089);
}
.footerSocials
{
    display: flex;
    align-items: center;
    
}
.footerSocials>span
{
    margin-right: 10px;color: #fff;
}
.footerSocials>a
{
    border: 1px solid #eee;
    padding: 3px 8px;
    font-size: 17px;
    color: var(--secondary-color);
   transition: 0.4s ease;
}
.footerSocials>a:nth-of-type(2)
{
    border-left: transparent;
    /* border-right: transparent; */
}
.footerSocials>a:nth-of-type(3)
{
    border-right: transparent;
}
.footerSocials>a:hover
{
    background: #eee;
}
.FooterContent
{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
}
.FooterContent>div

{
    margin-top: 40px;
}
.FooterConentTitle
{
    color: var(--secondary-color);
    margin-bottom: 20px;
}

.FooterContentPara
{
    color: rgb(218, 214, 214);
    font-size: 14px;
    
}
.FooterContact
{
    color: rgb(218, 214, 214);
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    
}
.FooterContact>span
{
text-align: left;
/* margin-right: 10px; */

}
.key
{
    width: 100px;
}
.FooterContact a
{
    text-decoration: none;
    text-align: left;
    color: rgb(218, 214, 214) ;
    transition: 0.3s ease;
}
.FooterContact a:hover
{
    color: var(--secondary-color);
}
#newsletterForm
{
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    margin-top: 12px;
}

#newsletterForm>input
{
background-color: #292929;
border: 1px solid rgba(238, 238, 238, 0.192);
width: 60%;
padding: 20px;
color: var(--white-text);
transition: 0.5s ease;
}
#newsletterForm>input:focus
{
    outline: 0;
    background: rgba(238, 238, 238, 0.233);
}
#newsletterForm>input,#newsletterForm>button
{
    height: 50px;
  
}
#newsletterForm>button
{
    background: var(--secondary-color);
    color: var(--white-text);
    width: 30%;
    margin-left: 12px;
    transition: 0.4s ease;
    outline: 0;
    border: 1px solid var(--secondary-color);
    font-weight: 500;
    font-size: 18;
    letter-spacing: 1px;
}

#newsletterForm>button:hover
{
    background: #fff;
    color: var(--primary-color);
}
.copyright
{
    
    padding: 2.5vh 0 ;
    color: var(--white-text);
    background: var(--primary-color);
}
.missingSection
{
    position: relative;
    height: 100vh;
    width: 100vw;
    background: url(/static/media/background2.f073e96e.jpg) rgba(17, 17, 17, 0.514);
    background-size: cover;
    background-blend-mode: darken;
    background-repeat: no-repeat;
}
.missingDiv
{
    position: relative;
    width: 90%;
    min-height: 70vh;
    color: #fff !important;
}
._404
{
    font-size: 23vh;
    color: #fff ;
}
.missingMessage
{
    text-transform: uppercase;
    letter-spacing: 2px;
    opacity: 0.6;
    -webkit-animation: flickerAnimation 3s infinite;
            animation: flickerAnimation 3s infinite;
    font-size: 16px;
    text-align: center;
}
.MissingBox
{
    width: 120px;
    height: 120px;
    position: absolute;
    border-color: var(--secondary-color);
    border-width: 2px;
}
@-webkit-keyframes flickerAnimation {
    0%   { opacity:1; }
    50%  { opacity:0.6; }
    100% { opacity:1; }
  }
@keyframes flickerAnimation {
    0%   { opacity:1; }
    50%  { opacity:0.6; }
    100% { opacity:1; }
  }
