.title
{
    text-transform: capitalize;
}
.smallText
{
    font-style: italic;
    color: var(--secondary-color);
    font-size: 18px;
    text-align: center;
}
.bigText
{
    font-size: 28px;
    text-align: center;
}