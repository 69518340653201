#contactBrush
{
  filter: brightness(104%);
}
.GalleryWrapper
{
    background: url('../../images/background2.jpg') rgba(17, 17, 17, 0.616);
    background-blend-mode: darken;
    background-size: cover;
    background-attachment: fixed;
    width: 100vw;
    margin: 0 auto;
}
.Galleryrow {
    width: 96vw;
    margin: 0 auto;
    display: -ms-flexbox; /* IE10 */
    display: flex;
    -ms-flex-wrap: wrap; /* IE10 */
    flex-wrap: wrap;
    padding: 5vh 4px;
    align-items: center;
    justify-content: center;
  }
  
  /* Create four equal columns that sits next to each other */
  .imageColumn {
    -ms-flex: 25%; /* IE10 */
    flex: 25%;
    max-width: 25%;
    padding: 0 4px;
  }
  
  .imageColumn img {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
  }
  
  /* Responsive layout - makes a two column-layout instead of four columns */
  @media screen and (max-width: 800px) {
    .imageColumn {
      -ms-flex: 50%;
      flex: 50%;
      max-width: 50%;
    }
  }
  

  .imageWrapper
  {
    overflow: hidden;
    width: 96%;
    height: 90%;
    margin: 12px auto;
    background: var(--primary-color);
    border: 5px solid transparent;
    transition: 0.5s ease;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; */
  }
  .imageWrapper>img
  {
      filter: brightness(0.9);
    transition: 0.5s ease;
    object-fit: cover;
  }
  .imageWrapper:hover
  {
    border-color: var(--secondary-color);
  }
 .imageWrapper>img:hover
  { filter: brightness(1);
      transform: scale(1.05);
      
  }