.brushImageDiv
{
    width: 100vw;
    height: 60px;
    position: relative;

}
.brushImage
{
    width: 100%;
    height: 100%;
    position: absolute;
    top: -100%;
    filter: brightness(124%);
}