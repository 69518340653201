.h-fscreen
{
    min-height: 100vh;
}
.flex

{
    display: flex;
}
.center
{
    display: flex;
    align-items: center;
    justify-content: center;
}

.column
{
    flex-direction: column;
}
.serif
{
    font-family: var(--serif-font);
    color: var(--white-text);
}

/* LOADER */
.preloader
{
    background: var(--primary-color);
    z-index: 99999;
    position: fixed;
    width: 100vw;
    height: 100vh;
}
.loader1 {
    display:inline-block;
    font-size:0px;
    padding:0px;
 }
 .loader1 span {
    vertical-align:middle;
    border-radius:100%;
    
    display:inline-block;
    width:20px;
    height:20px;
    margin:3px 2px;
    -webkit-animation:loader1 0.8s linear infinite alternate;
    animation:loader1 0.8s linear infinite alternate;
 }
 .loader1 span:nth-child(1) {
    -webkit-animation-delay:-1s;
    animation-delay:-1s;
   background:#c19d608a;
 }
 .loader1 span:nth-child(2) {
    -webkit-animation-delay:-0.8s;
    animation-delay:-0.8s;
   background:#c19d60a4;
 }
 .loader1 span:nth-child(3) {
    -webkit-animation-delay:-0.26666s;
    animation-delay:-0.26666s;
   background:#C19D60;
 }
 .loader1 span:nth-child(4) {
    -webkit-animation-delay:-0.8s;
    animation-delay:-0.8s;
   background:#c19d60b4;
   
 }
 .loader1 span:nth-child(5) {
    -webkit-animation-delay:-1s;
    animation-delay:-1s;
   background:#c19d6052;
 }
 
 @keyframes loader1 {
    from {transform: scale(0, 0);}
    to {transform: scale(1, 1);}
 }
 @-webkit-keyframes loader1 {
    from {-webkit-transform: scale(0, 0);}
    to {-webkit-transform: scale(1, 1);}
 }

.AlternateWrapper
{
    min-height: 70vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
 .AlternateDetails

 {
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
 }
 .AlternateContent
 {
     color: var(--secondary-color);
     font-size: 28px;
     text-align: center;
 }
 .AlternateHeading{
     color: #fff;
     width: max-content;
     font-size: 6vh;
     font-weight: 700;
     margin-top: 12px;
 }
 input
 {
     color: var(--secondary-color) !important;
 }