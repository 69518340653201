.TeamWrapper
{
    /* background: var(--primary-color) ; */
}
.TeamWrapper
{
    position: relative;
    width: 100%;
    padding-top: 5vh;
}
@media screen and (max-width:800px) {
    .TeamWrapper>.topBorder
    {
        display: none;
    }
    
}
.teamCardWrapper
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.teamCard
{
    background: var(--dark-text);
    padding: 0;
    margin: 20px;
   
}

.teamImage
{
    width: 100%;
    height: 250px;
    position: relative;
    overflow: hidden;
   
}
.teamImage>img
{
    width: 100%;
    height:100%;
    object-fit: cover;
    filter: brightness(80%);
}
.TeamBox
{
    position: absolute;
    height: 50px;
    width: 50px;
}
.teamCardDetails
{   padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px -1px 4px -2px var(--white-text) inset;
    border-top: transparent;
}
.teamImage>.borderBoxTop
/* ,.teamImage>.borderBoxBottom */
{
    border-top: 2px dotted var(--secondary-color);
    border-left: 2px dotted var(--secondary-color);
}
.teamImage>.borderBoxBottom
{
    border-right: 2px dotted var(--secondary-color);
    border-bottom: 2px dotted var(--secondary-color);
}
.teamCardDesignation
{
    color: var(--secondary-color);

}
.teamCardpara
{
    color: var(--white-text);
    width: 80%;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.5px;
}

.teamCard .dots
{
    color: var(--secondary-color);
}