.navSection
{
width: 80vw;
position: fixed;
margin: 0 10vw;
z-index: 999;
top: 0;
transition: 0.5s ease;
}

.topWrapper
{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    
    width: 100%;
    margin: 0 auto;
    padding: 15px 0;
    padding-bottom: 12px;
  
}
.topbarDetailWrapper
{
    margin: 0 12px;
}
.topbarDetailWrapper>span
{
    font-family: var(--serif-font);
    color: #fff;
}
.topbarDetailWrapper>.type
{
    color: var(--secondary-color);
    font-weight: 700;
    margin-right: 6px;
}

.navBar
{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0.15px solid var(--white-text);
    border-right: transparent;
    border-radius: 10px;
    background: var(--primary-color);
    height: 80px;
    padding: 0 40px;
    padding-right: 0;
    transition: 0.4s ease;
}
.navBrand
{
    height: 80%;
   
}
.navBrand>img
{
    height: 100%;
    width: auto;
    image-rendering: optimizeQuality;
}
.navMenu
{
    display: flex;
    align-items: center;
}
.navItem
{
    color: var(--white-text);
    margin: 0 12px;
    font-size: 14px;
    font-weight: 600;
    transition: 0.5s ease;
}
.active,.navItem:hover
{
    color: var(--secondary-color);
}
.navRight
{
    display: flex;
    align-items: center;
    justify-content: space-between;
   position: relative;
    right: 0;
}
.hamMenu
{
    display: none;
}

.lightDarkButton
{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border:1px solid var(--secondary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white-text);
    background: var(--secondary-color);
    height:80px;
    flex: 0.5;
    padding: 0 20px;
    font-size: 3vh;
    position: relative;
    margin-left: 20px;
   transition: 0.5s ease;
}
.lightDarkButton>i
{
    width: 20px;
}
@media screen and (max-width:800px) {
    .topWrapper
    {
        justify-content: space-around;
        padding-top:10px;
        padding-bottom: 0;
    }
    .hamMenu
    {
        display: flex;
        flex-direction: column;
    }
    #closeBtn
    {
        display: none;
    }
    .hamMenu>span
    {
        width: 30px;
        height: 2px !important;
        background: var(--white-text);
        margin: 4px;
        transition: 0.3s ;
    }  

    .navMenu
    {   
        display: none;
        flex-direction: column;
        position: absolute;
        top: 100%;
        right: 0;
        width: 30%;
        align-items: flex-start;
        background: var(--primary-color);
        padding: 12px;
        height: 0;
        
    }
    .open
    {
        height: auto;
        display: flex;
        transition: 0.5s ease !important;
    }
    .navItem
    {
        font-size: 18px;
        padding: 5px 0;
        margin-bottom: 7px;
    }
    
}

@media screen and (max-width:480px) {
    
    .navSection
    {
        width: 90vw;
        margin: 0 5vw;
    }
    .navBar
    {
        padding-left: 10px;
        padding-right: 0;
    }
    .topWrapper
    {
        /* flex-direction: column;
        align-items: center;
        justify-content: center; */
        font-size: 12px;
    }
    .navMenu
    {
        width: max-content;
        position: absolute;
        padding: 40 80;
    }
    .navItem
    {
        /* border-bottom:1px solid var(--secondary-color); */
        width: 100%;
        text-align: left;
        
    }
    .navBar
    {
        margin-top: 10px;
    }
}