.loader-wrap {
	position: fixed;
	top:0;
	bottom:0;
	left:0;
	right:0;
	overflow:hidden;
	z-index:100000000;
}
.loader {
	position: absolute;
	top:50%;
	left:50%;
	width:40px;
	height:40px;
	margin:-20px 0 0 -20px;
	z-index:20;
    transform: scale(1.0);
}
.loader:before{
	content:'';
	position:absolute;
	top:50%;
	left:50%;
	width:40px;
	height:40px;
	margin:-20px 0 0 -20px;
	border: 2px solid var(--secondary-color);
	border-top: 2px  solid;
	border-radius: 50%;
	animation: spin2 0.5s infinite linear;
	border-radius:100%;
	z-index:1;
}
@keyframes spin2 {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}
.cd-tabs-layer  , .cd-reserv-overlay-layer {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	height: 100%;
	width: 100%;
	opacity: 0;
	visibility: hidden;
	overflow: hidden;
}
.cd-loader-layer {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	height: 100%;
	width: 100%;
	opacity: 1;
	visibility: visible;
	overflow: hidden;
}
.cd-loader-layer .loader-layer , .cd-tabs-layer .tabs-layer , .cd-reserv-overlay-layer .reserv-overlay-layer {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateY(-50%) translateX(-98%);
	height: 100%;
	width: 2500%;
	background: url('../../images/ink2.png') no-repeat 0 0;
	background-size: 100% 100%;
}
.cd-tabs-layer.visible, .cd-loader-layer.visible , .cd-reserv-overlay-layer.visible {
	opacity: 1;
	visibility: visible;
}
.cd-tabs-layer.opening .tabs-layer , .cd-loader-layer.opening .loader-layer , .cd-reserv-overlay-layer.opening .reserv-overlay-layer  {
	animation: cd-sequence 0.8s steps(24);
	animation-fill-mode: forwards;
}
.cd-tabs-layer.closing .tabs-layer   , .cd-reserv-overlay-layer.closing .reserv-overlay-layer {
	animation: cd-sequence-reverse 0.8s steps(24);
	animation-fill-mode: forwards;
}
.cd-loader-layer.closing .loader-layer{
	animation: cd-sequence-reverse 0.8s steps(24);
	animation-fill-mode: forwards;
} 
@keyframes cd-sequence {
  0% {
    transform: translateY(-50%) translateX(-2%);
  }
  100% {
    transform: translateY(-50%) translateX(-98%);
  }
}
@keyframes cd-sequence-reverse {
  0% {
    transform: translateY(-50%) translateX(-98%);
  }
  100% {
    transform: translateY(-50%) translateX(-2%);
  }
}