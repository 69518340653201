.openingHours
{
    position: relative;
    padding: 10vh 0;
    background: var(--primary-color);
}
.openingHours>.row
{
    align-items: flex-start;
}
.openingWrapper
{
    min-height: 80vh;
    padding: 20px 0;
    

}
.quoteDiv
{
    background: url('../../images/DSC_9552.jpg') rgba(29, 29, 29, 0.788);
    background-blend-mode: overlay !important;
    background-size: cover !important;
    /* background-attachment: fixed; */
    background-position: right;
    background-position: center !important;
    transform: translateZ(0);
   
}


.quoteWrapper
{   height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
}

#quoteIcon
{
    color: transparent;
    -webkit-text-stroke: 1px;
    -webkit-text-stroke-color: var(--secondary-color);
    font-weight: 800;
    font-size: 5vh;
}

.quote
{
    width: 90%;
    text-align: center;
    color: #fff;
    font-family: var(--serif-font);
    margin-top: 10px;
    letter-spacing: 1px;
    text-shadow: black 0.1em 0.1em 0.2em
}
.designation
{   width: 90%;
    margin: 50px auto;
    font-size: 19px;
    font-weight: 700;
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    justify-content: space-around;
    text-align: center;
}
.designation>img,.designation>span
{
    margin-top: 2%;
}
.hourDiv
{
    background: url('../../images/floarl2.png') rgba(104, 104, 104, 0.479);
    background-size: cover;
    background-blend-mode: normal;
    background-position: left;
    backdrop-filter: contrast(100%);
}


.hourWrapper
{
    width: 90%;
    height: 90%;
    background: rgba(17, 17, 17, 0.534);
    border: 1px dotted var(--secondary-color);
    padding: 20px 0;
}
.hourWrapper>div>span
{
    color: var(--secondary-color);
}
.hourWrapper>div>span:nth-of-type(1)
{
    font-style: italic;
    font-size: 18px;
}
.hourWrapper>div>h3
{
    font-size: 38px;
    font-weight: 700;
    color: #fff;
}
.timingWrapper
{
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    align-items: flex-start;
    justify-content: space-evenly;
    
}
.timingDiv
{   
    color: #fff;
    margin-top: 30px;
}
.timingDiv:nth-of-type(1)::after
{
    content: '';
    position: absolute;
    height: 100%;
    width: 2px;
    background: var(--secondary-color);
    right: 0;
}
.timingDay
{
    color: var(--secondary-color);
}
.timing
{
    font-size: 30px;
    font-weight: 700;
    position: relative;
    margin: 7px 0;
}
.timing:nth-of-type(2n+1)::after
{
    content: '';
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 0.15px;
    background: var(--secondary-color);
}
.reservationBtn
{
    margin: 40px auto;
    text-align: center;
    padding: 12px 20px;
    font-size: 4vh;
    border: 1px solid var(--secondary-color);
    transition: 0.3s ease;
    cursor: pointer;
    color: #fff !important;
}
.reservationBtn:hover
{
    color: var(--secondary-color);
    
}

@media screen and (max-width:900px) {
    .timingDiv:nth-of-type(1)::after
    {
        display: none;
    }
}
@media screen and (max-width:480px) {
    .timingDiv:nth-of-type(1)::after
    {
        display: none;
    }
    .timing:nth-of-type(2n+1)::after
    {
        display: none;
    }
}