@import './button.css';

/* .preloader
{
    animation: remove 3s ;
}
@keyframes remove
{
    from {
        opacity: 1;
    }
    to
    {
        opacity: 0;
        
    }
} */
.landingWrapper
{
    /* background: url('../../images/background2.jpg') rgba(17, 17, 17, 0.527); */
    /* background-size: cover;
    background-blend-mode: darken;
    background-attachment: fixed;
    background-repeat: no-repeat; */
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 10vw;
    overflow: hidden;
    position: relative;
}

.landingDetails
{
color: var(--white-text);
height: max-content;
}
.dots
{
    font-size: 31px;
    font-weight: 100;
    margin-bottom: 12px;
    color: #fff;
    font-style: italic;
    letter-spacing: 2px;
}
.landingContent
{
    font-family: var(--serif-font);
    font-size: 21px;
    font-style: italic;
    color: var(--secondary-color);
}
.landingHeading
{
    width: 90%;
    font-size: 8vh;
    font-weight: 800;
    color: #fff;
}
.landingBtn
{
    border: 1px solid var(--secondary-color);
    color: #fff;
    background: rgba(112, 111, 111, 0.137);
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 12px 20px;
    font-weight: 600;
    letter-spacing: 1px;
    font-family: var(--serif-font);
    margin-top: 40px;
    font-style: normal;
}
.landingBtn:hover
{
    background: #fff;
    color: var(--secondary-color);
    transition: 0.5s ease;
}
.landingBtn>span
{
    margin-right: 12px;
}
/* .mobileBackground
{
    
    background: url('../../images/backgroundmobile.jpg') rgba(17, 17, 17, 0.589) !important;
    background-size: cover;
    background-position: center;
    background-blend-mode: darken;
    background-attachment: fixed;
    background-repeat: no-repeat;
    min-height: 130vh;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 0 10vw;
    padding-bottom: 10vh;
} */

@media screen and (max-width:800px) {
    .landingDetails
    {
        margin-top: 60px;
    }
    .landingHeading
    {
        font-size: 7vh;
    }
    .landingWrapper
{
    background: url('../../images/backgroundmobile.jpg') rgba(17, 17, 17, 0.589);
    background-size: cover;
    background-position: center;
    background-blend-mode: darken;
    background-attachment: scroll;
    background-repeat: no-repeat;
    min-height: 130vh;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    justify-content: center;
    padding: 0 10vw;
    padding-bottom: 0vh;
}
.buttonContainer
{   position: relative;
    top: 0;
    margin: 0 auto;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
}
#landingSlider
{
    display: none;
    width: 100%;
    
}
}


.promoVideo
{
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: #111;
    z-index: 99999;
     display: none;
   
    align-items: center;
    justify-content: center;
    transition: 0.5s ease;
    transition-delay: 1s;
}
.promoVideo>iframe
{
    width: 70vw;
    height: 70vh;
}
.closeVideo
{
    position: absolute;
    top: 2%;
    right: 4%;
    color: #fff;
    font-size: 6vh;
    cursor: pointer;
   
}


#landingSlider
{
    width: 100%;
    min-height: 100vh;
    position: absolute;
}
.carousel
{
    position:static;
    top: 0;
}
.carousel-item
{
    transition: 3s ease !important;
}
.carousel-control
{
    opacity: 0;
}
.landing_background
{
    width: 100vw;
    height: 100vh;
    position: relative;
}
.landing_background>img
{
    width: 110%;
    height: 100%;
    position: relative;
    object-fit: cover;
    object-position: center;
}
.overlay
{
    position: absolute;
    content: ' ';
    width: 100%;
    height: 100%;
    background: rgba(41, 41, 41, 0.596);
    top: 0;
    left: 0;
}
/* .carousel-control
{
    background: var(--primary-color);
    font-size: 30px;
    opacity: 1;
    width: fit-content;
    height: fit-content;
    border-radius: 50%;
    padding:12px 20px;
    padding-left: 18px;
} */

@media screen and (max-width:800px) {
    #landingSlider
    {
        width: 100%;
        
    }
    
}